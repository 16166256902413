import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { statusOptions } from '../../settings';
import InspectionCage from './InspectionCage';
import ModalMD from './partials/ModalMD';
import { connect } from 'react-redux';
import _orderBy from 'lodash/orderBy';
import {
  changeStatusInspection,
  updateVehicleApi,
  adminSetStandard,
  holding,
  putSkipVin,
  invalid,
  qcPassed,
  clearInspection,
} from '../../operations';
import { logout } from '../../../auth/operations';
import CarStatus from './partials/CarStatus';
import CountDown from './partials/CountDown';
import SVG from 'react-inlinesvg';
const displayDurationTime = (timeProcess, timeFinish, type) => {
  const processAt = new Date(timeProcess);
  const finishAt = new Date(timeFinish);
  const diff = finishAt.getTime() - processAt.getTime();
  const inSeconds = Math.round(diff / 1000);
  const inMinutes = Math.floor(inSeconds / 60);
  const secondLeft = inSeconds - inMinutes * 60;
  let color = 'dark';
  if (type === 'VALIDATED') color = 'warning';
  if (type === 'INSPECTED') color = 'danger';
  if (type === 'CHECKED') color = 'dark';
  return (
    <div
      style={{
        fontSize: '16px',
      }}
      className={`badge badge-pill d-flex align-items-center text-head badge-${color} mb-1`}
    >
      <span className="mr-1">{type}</span>
      <span className="mr-1">:</span>
      <span>{inMinutes}min</span>
      <span>{secondLeft}s</span>
    </div>
  );
};
const displayInfoItem = (label, value, checkScroll) => {
  return (
    <div
      className={`text-head text-uppercase ${
        checkScroll ? '' : 'badge_view_info'
      }`}
      style={{ fontSize: checkScroll ? '80%' : '' }}
    >
      <span className="text-logan mr-2">{label}</span>
      <b className="text-dark">{value ? value : ''}</b>
    </div>
  );
};

const displayStatusTitle = (
  status,
  standard,
  roleUser,
  sessionID,
  showModalConfirm,
) => {
  let bgColor = '#321640';
  switch (status) {
    case 'CONFIRMED':
      bgColor = '#28a745';
      break;
    case 'INSPECTED':
      bgColor = '#28a745';
      break;
    case 'FINISHED':
      bgColor = '#28a745';
      break;
    case 'PROCESSING':
      bgColor = '#007bff';
      break;
    case 'INSPECTING':
      bgColor = '#007bff';
      break;
    case 'CHECKING':
      bgColor = '#007bff';
      break;
    case 'INVALID':
      bgColor = '#EA2027';
      break;
    case 'HOLDING':
      bgColor = '#f1c40f';
      break;
    case 'VIN-CHECKING':
      bgColor = '#f1c40f';
      break;
    case 'VIN-CONFIRM':
      bgColor = '#f1c40f';
      break;
    case 'NEED-VALIDATE':
      bgColor = '#f1c40f';
      break;
    case 'VALIDATING':
      bgColor = '#f1c40f';
      break;
    case 'NEED-CONFIRM':
      bgColor = '#f1c40f';
      break;
    case 'IN-QUEUE':
      bgColor = '#bfbfbf';
      break;
  }
  return (
    <div className="d-flex align-items-center  mb-2" style={{ width: '100%' }}>
      <span
        className="badge badge-dark badge-pill text-head d-flex justify-content-center align-items-center mr-1 py-2"
        style={{ backgroundColor: bgColor, width: '98%', fontSize: '30px' }}
      >
        {status === 'COMPLETED' ? 'PAVE' : status}
      </span>
      {['FINISHED', 'COMPLETED'].includes(status) ? (
        <div className="d-flex" style={{ cursor: 'pointer' }}>
          {roleUser === 'SUPER' && standard ? (
            <i
              onClick={() => showModalConfirm(sessionID, standard)}
              style={{
                fontSize: '21px',
              }}
              className="fas fa-star text-success"
            ></i>
          ) : roleUser === 'SUPER' && !standard ? (
            <i
              onClick={() => showModalConfirm(sessionID, standard)}
              style={{
                fontSize: '21px',
              }}
              className="fas fa-question text-primary"
            ></i>
          ) : roleUser !== 'SUPER' && standard ? (
            <i
              style={{
                fontSize: '21px',
              }}
              className="fas fa-star text-success"
            ></i>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

const displayStatusLabelScroll = (status) => {
  const statusOption = statusOptions[status] || {};
  return (
    <div
      className={`badge badge-${statusOption.style} mb-0`}
      style={{ background: status === 'COMPLETED' ? '#321640' : '' }}
    >
      <span className="text-head">{statusOption.label || status}</span>
    </div>
  );
};

const displayProcessReport = (completedInspect, checkScroll) => {
  return (
    <button
      className={`btn btn-success ${
        checkScroll ? 'btn-md mr-1' : 'btn-tiny px-1 mr-1'
      }`}
      onClick={() => completedInspect()}
      style={{ borderRadius: checkScroll ? '15px' : '', width: '100%' }}
    >
      <i className="fas fa-clipboard-check"></i>
      <span>Complete</span>
    </button>
  );
};

const InspectionViewInfo = (props) => {
  const { item, tasks, newTab, auth, setautoRefresh } = props;
  window.onscroll = () => {
    const currentScrollPos = window.pageYOffset;
    if (currentScrollPos > 200) {
      setshowInfoScroll(true);
    } else {
      setshowInfoScroll(false);
    }
  };

  const inspTasks = _orderBy(tasks, ['photoCode'], ['asc']);
  let photoMissing = [];
  let ppWarning = [];
  let showSttPhoto = [];
  let photoExist = [];
  let checkCompleted = false;
  let listUrlOriginal = [];
  for (let i = 0; i < inspTasks.length; i++) {
    const task = inspTasks[i];
    if (task.status !== 'REJECTED') {
      photoExist.push(task.photoCode);
      listUrlOriginal.push(task.image_cap);
    }
  }

  const inspectionID = item?.inspectionID ?? null;
  const sessionID = item?.sessionID ?? null;
  const priority_level = item?.options.priority_level ?? 'F1';
  const decode = item?.options?.decode ?? '';
  const qcPassed = item?.qcPassed;
  const inspector = item?.inspector;
  const validator = item?.validator;
  const qa = item?.qa;
  const manager = item?.manager ?? null;
  const skipVin = item?.skipVin ?? false;
  const userConfirmTire = item?.userConfirmTire?.confirm ?? null;
  const statusInspection = item?.status ?? '';
  const damage_table = item?.options?.damage_table ?? '';
  const checkBmw = damage_table ? damage_table.includes('bmw') : false;
  const usecase = item?.options?.usecase ?? '';

  const roleUser = auth?.user?.role ?? 'ADMIN';

  const username = auth?.user?.username ?? '';

  const qcPassedAt = item?.qcPassedAt ? new Date(item.qcPassedAt) : null;

  const autoInspectedAt = item.autoInspectedAt
    ? new Date(item.autoInspectedAt)
    : null;
  const autoCheckedAt = item.autoCheckedAt
    ? new Date(item.autoCheckedAt)
    : null;
  const autoValidatedAt = item.autoValidatedAt
    ? new Date(item.autoValidatedAt)
    : null;
  const autoFinishedAt = item.autoFinishedAt
    ? new Date(item.autoFinishedAt)
    : null;
  const priority_ttc =
    item.options && item.options.priority_ttc
      ? item.options.priority_ttc * 1000
      : 900 * 1000;

  const inspectedAt = item.inspectedAt ? new Date(item.inspectedAt) : null;
  const inspectSuccessedAt = item.inspectSuccessedAt
    ? new Date(item.inspectSuccessedAt)
    : null;
  const validatedAt = item.validatedAt ? new Date(item.validatedAt) : null;
  const validateSuccessedAt = item.validateSuccessedAt
    ? new Date(item.validateSuccessedAt)
    : null;
  const total_exterior =
    item?.reportData?.inspection?.repair_estimate?.total_exterior;
  const total_excess_wear =
    item?.reportData?.inspection?.repair_estimate?.total_excess_wear;
  const currency = item?.reportData?.inspection?.repair_estimate?.currency;
  const total_wheels_and_tires =
    item?.reportData?.inspection?.repair_estimate?.total_wheels_and_tires;
  const checkedAt = item.checkedAt ? new Date(item.checkedAt) : null;
  const checkSuccessedAt = item.checkSuccessedAt
    ? new Date(item.checkSuccessedAt)
    : null;
  const managedAt = item.managedAt ? new Date(item.managedAt) : null;
  const manageSuccessedAt = item.manageSuccessedAt
    ? new Date(item.manageSuccessedAt)
    : null;
  const holding = item.holding ? item.holding : false;
  const finishedAt = item.finishedAt ? new Date(item.finishedAt) : null;
  const processAt = item.processAt
    ? new Date(item.processAt)
    : finishedAt
    ? new Date(new Date(item.finishedAt).getTime() + priority_ttc)
    : null;
  const timezone =
    auth && auth.user && auth.user.options && auth.user.options.timezone
      ? auth.user.options.timezone
      : 'Asia/Ho_Chi_Minh';
  const createdAt = item && item.createdAt ? new Date(item.createdAt) : null;

  const qcPassedAt7 = qcPassedAt
    ? qcPassedAt.toLocaleString('en-US', {
        timeZone: timezone,
      })
    : null;
  const createdAt7 = createdAt
    ? createdAt.toLocaleString('en-US', {
        timeZone: timezone,
      })
    : null;
  const processAt7 = processAt
    ? processAt.toLocaleString('en-US', {
        timeZone: timezone,
      })
    : null;
  const finishedAt7 = finishedAt
    ? finishedAt.toLocaleString('en-US', {
        timeZone: timezone,
      })
    : null;
  let checkButtonInvalid = false;
  let checkShowButtonVehicle = true;
  let checkShowButtonCage = true;
  let checkShowButtonHolding = false;
  let checkShowButtonQcPassed = false;
  let checkShowButton = false;
  let showButtonChangeStatus = false;
  let showClockQcPassed = false;
  let showClockFull = false;
  let showClockAgent = false;
  let showClockQa = false;
  let showClockValidator = false;
  let showModalTimeOut = false;
  let showSkipVin = false;
  if (['QA', 'AGENT'].includes(roleUser)) {
    if (username === inspector) {
      if (statusInspection === 'INSPECTING') {
        checkShowButton = true;
        checkShowButtonHolding = true;
      }

      const timeoutAutoInspected = autoInspectedAt
        ? autoInspectedAt.getTime() - Date.now()
        : null;
      if (
        timeoutAutoInspected &&
        timeoutAutoInspected < 10000 &&
        !inspectSuccessedAt
      ) {
        checkShowButton = false;
        checkShowButtonHolding = false;
      }
      if (
        inspectSuccessedAt &&
        ['INSPECTING', 'INSPECTED'].includes(statusInspection)
      ) {
        const durationTimeInspected = Math.abs(
          inspectSuccessedAt.getTime() - autoInspectedAt.getTime(),
        );
        if (durationTimeInspected < 6000) {
          showModalTimeOut = true;
          setautoRefresh(false);
        }
      }
      if (!inspectSuccessedAt) {
        showClockAgent = true;
      }
    }
    if (username === qa) {
      if (statusInspection === 'CHECKING') {
        checkShowButtonHolding = true;
        checkShowButton = true;
      }
      const timeoutCheckedAt = autoCheckedAt
        ? autoCheckedAt.getTime() - Date.now()
        : null;

      if (timeoutCheckedAt && timeoutCheckedAt < 10000 && !finishedAt) {
        checkShowButton = false;
        checkShowButtonHolding = false;
      }
      if (
        checkSuccessedAt &&
        ['CHECKING', 'FINISHED', 'VIN-CONFIRM'].includes(statusInspection)
      ) {
        const durationTimeCheckedAt = Math.abs(
          checkSuccessedAt.getTime() - autoCheckedAt.getTime(),
        );
        if (durationTimeCheckedAt < 6000) {
          showModalTimeOut = true;
          setautoRefresh(false);
        }
      }
      if (!checkSuccessedAt) {
        showClockQa = true;
      }
    }
    if (username === validator) {
      if (autoValidatedAt) {
        const timeout = autoValidatedAt.getTime() - Date.now();
        if (timeout < 6000 && !validateSuccessedAt) {
          showModalTimeOut = true;
          setautoRefresh(false);
        }
      }
      if (!validateSuccessedAt) {
        showClockValidator = true;
      }
    }
    if (holding) {
      checkShowButton = false;
      checkShowButtonHolding = false;
    }
    if (manager) {
      checkButtonInvalid = false;
      checkShowButton = false;
      checkShowButtonVehicle = false;
      checkShowButtonCage = false;
      checkShowButtonHolding = false;
    }
    if (
      ['COMPLETED', 'FINISHED', 'INSPECTED', 'VIN-CONFIRM'].includes(
        item.status,
      )
    ) {
      checkShowButtonHolding = false;
      checkButtonInvalid = false;
      checkShowButton = false;
      checkShowButtonVehicle = false;
      checkShowButtonCage = false;
      showSkipVin = false;
    }
  }
  if (['SUPER', 'TRAINER', 'ADMIN'].includes(roleUser)) {
    if (!skipVin) {
      showSkipVin = true;
    }
    if (!qcPassed) {
      checkShowButtonQcPassed = true;
    }
    if (!finishedAt) {
      showClockQcPassed = true;
      showClockFull = true;
    }
    if (username === manager) {
      checkButtonInvalid = true;
      checkShowButtonHolding = true;
      if (
        !['COMPLETED', 'FINISHED', 'CONFIRMED', 'INSPECTED'].includes(
          item.status,
        )
      ) {
        checkShowButton = true;
      }
    }
    if (['COMPLETED', 'FINISHED'].includes(item.status)) {
      showButtonChangeStatus = true;
      checkShowButtonHolding = false;
      checkButtonInvalid = false;
      showSkipVin = false;
    }
  }
  if (['INVALID'].includes(item.status)) {
    checkButtonInvalid = false;
    checkShowButton = false;
    checkShowButtonVehicle = false;
    checkShowButtonCage = false;
    checkShowButtonHolding = false;
    showSkipVin = false;
  }

  const [modalStatusInspect, setmodalStatusInspect] = useState(false);
  const [modalStatusCompleted, setmodalStatusCompleted] = useState(false);
  const [modalCompleted, setmodalCompleted] = useState(false);
  const [modalHolding, setmodalHolding] = useState(false);
  const [modalSkipVin, setmodalSkipVin] = useState(false);
  const [modalQcPassed, setmodalQcPassed] = useState(false);
  const [modalInvalid, setmodalInvalid] = useState(false);
  const [showInfoScroll, setshowInfoScroll] = useState(false);
  const [inputVehicle, setinputVehicle] = useState(false);
  const [inputVrm, setinputVrm] = useState(false);
  const [createVehicle, setcreateVehicle] = useState(false);
  const [dataVehicle, setdataVehicle] = useState({});
  const [warningVinInput, setWarningVinInput] = useState(false);
  const [modalConfirm, setmodalConfirm] = useState(false);
  const [dataUpdate, setdataUpdate] = useState({});
  const [listUrl, setlistUrl] = useState(false);
  const [runOneTime, setrunOneTime] = useState(false);
  const [modalWarningVehicle, setmodalWarningVehicle] = useState(false);
  let vehicle = item.vehicle
    ? item.vehicle.data
      ? item.vehicle.data
      : item.vehicle
    : {};
  let cage = item.cage || {};
  useEffect(() => {
    if (
      !runOneTime &&
      vehicle &&
      (!vehicle.body_type ||
        vehicle.make === 'Default' ||
        vehicle.model.includes('Default'))
    ) {
      setmodalWarningVehicle(true);
    }
    setrunOneTime(true);
    return () => {
      setrunOneTime(false);
    };
  }, [modalWarningVehicle]);
  const dataSameVinInspect =
    item && item.sameInspection ? item.sameInspection : null;
  const capture_photo_codes =
    item?.options?.customized?.capture_photo_codes ?? [];
  if (item.inspectionThemes && item.inspectionThemes.name === 'PAVE-PASS') {
    ppWarning.push(
      <div key="Is_PP" className="badge badge-dark">
        <span className="text-warning">PAVE-PASS</span>
      </div>,
    );
    const processPhotoCodes =
      item.options && item.options.processPhotoCodes
        ? item.options.processPhotoCodes
        : [];
    const ppDirectFacing = ['04', '05', '07', '08'].every((c) =>
      processPhotoCodes.includes(c),
    );
    const ppDiagonalFacing = ['10', '11', '12', '13'].every((c) =>
      processPhotoCodes.includes(c),
    );
    ppWarning.push(
      <div
        key="Is_PP_Process_Codes"
        className="badge badge-light"
        style={{ fontWeight: 'bold' }}
      >
        <span className="text-logan">
          ({processPhotoCodes.length}){processPhotoCodes.join(',')}
        </span>
      </div>,
    );
    ppWarning.push(
      <div
        key="Is_PP_Missing_Direct"
        style={{ fontWeight: 'bold' }}
        className={`badge badge-light ${
          ppDirectFacing ? 'text-success' : 'text-danger'
        }`}
      >
        <i
          className={`fa fa-lg fa-${
            ppDirectFacing ? 'check' : 'exclamation'
          }-circle`}
        ></i>
        <span className="">Direct Facing</span>
      </div>,
    );

    ppWarning.push(
      <div
        key="Is_PP_Missing_Diagonal"
        style={{ fontWeight: 'bold' }}
        className={`badge badge-light ${
          ppDiagonalFacing ? 'text-success' : 'text-danger'
        }`}
      >
        <i
          className={`fa fa-lg fa-${
            ppDiagonalFacing ? 'check' : 'exclamation'
          }-circle`}
        ></i>
        <span className="">Diagonal Facing</span>
      </div>,
    );
  } else {
    if (photoExist && photoExist.length > 0) {
      let photoDefault = [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
      ];
      if (capture_photo_codes.length > 0) {
        photoDefault = capture_photo_codes.map((item) => {
          if (item > 9) {
            return `${item}`;
          } else {
            return `0${item}`;
          }
        });
      }
      const customized =
        item.options && item.options.customized
          ? item.options.customized
          : null;
      const skipTire =
        item.options && item.options.skip_tire ? item.options.skip_tire : false;
      let taskSkip = [];
      if (
        customized &&
        customized.allow_skip &&
        customized.allow_skip.length > 0
      ) {
        for (let i = 0; i < customized.allow_skip.length; i++) {
          const photoCode = customized.allow_skip[i];
          taskSkip.push(photoCode);
        }
      }
      if (skipTire) {
        taskSkip.push('06');
      }
      if (customized) {
        if (customized.skip_vin) {
          taskSkip.push('01');
        }
        if (customized.skip_interior) {
          taskSkip.push('02');
        }
      }
      photoMissing = photoDefault.filter(
        (item) => !photoExist.includes(item) && !taskSkip.includes(item),
      );
      if (photoMissing && photoMissing.length > 0) {
        checkCompleted = true;
        showSttPhoto.push(
          <div
            key="Missing_Reject"
            className="badge badge-warning mr-1"
            style={{ fontWeight: 'bold' }}
          >
            <span className="text-dark">Rejected Or Missing</span>
          </div>,
        );
        showSttPhoto.push(
          <div
            key="Photo_Missing_Reject"
            className="badge badge-light"
            style={{ fontWeight: 'bold' }}
          >
            <span className="text-dark">{photoMissing.join(',')}</span>
          </div>,
        );
      }
    }
  }
  const showModalConfirm = (sessionID, standard) => {
    const data = {};
    data['sessionID'] = sessionID;
    data['standard'] = !standard;
    setdataUpdate(data);
    setmodalConfirm(true);
  };
  const adminSetStandard = () => {
    setmodalConfirm(false);
    props.adminSetStandard(dataUpdate);
  };
  const completedInspect = () => {
    setmodalCompleted(true);
  };
  const holdingInspection = async () => {
    const data = {
      sessionID: item.sessionID,
    };
    const res = await props.holding(data);
    if (res.success) {
      setmodalHolding(false);
      if (['QA', 'AGENT'].includes(roleUser)) {
        const currentDomain = window.location.origin;
        const newPath = `/inspect`;
        window.location.href = currentDomain + newPath;
      }
    }
  };
  const putSkipVin = () => {
    const data = {
      sessionID: item.sessionID,
    };
    props.putSkipVin(data);
    setmodalSkipVin(false);
  };
  const logOut = () => {
    props.logout();
  };
  const qcPassedInspection = () => {
    const sessionID = item.sessionID;
    props.qcPassed(sessionID);
    setmodalQcPassed(false);
  };
  const invalidInspection = () => {
    const data = {
      sessionID: item.sessionID,
    };
    props.invalid(data);
    setmodalInvalid(false);
  };
  const completeInspection = async () => {
    setmodalCompleted(false);
    props.onCompleteInspection();
  };
  const onToggleCreate = () => {
    setcreateVehicle(!createVehicle);
    setdataVehicle({});
    setWarningVinInput(false);
  };
  const checkVinInput = (vin) => {
    let patternCheckVin = /I|Q|O|[^A-Z0-9]/;
    if (vin.length !== 17 || patternCheckVin.test(vin)) {
      setWarningVinInput(true);
    } else {
      setWarningVinInput(false);
    }
  };
  const handleOptionChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    const data = {};
    if (['vin', 'serial', 'pin'].includes(name)) {
      if (usecase === 'holman') {
        let list = ['vin', 'serial', 'pin'];
        switch (name) {
          case 'serial':
            list = ['vin', 'pin'];
            break;
          case 'pin':
            list = ['vin', 'serial'];
            break;
          case 'vin':
            list = ['serial', 'pin'];
            break;
          default:
            break;
        }
        if (value && value.length > 0) {
          for (let i = 0; i < list.length; i++) {
            $(`#${list[i]}`).attr('disabled', true);
          }
        } else {
          for (let i = 0; i < list.length; i++) {
            $(`#${list[i]}`).attr('disabled', false);
          }
        }
      }
      value = value ? value.toUpperCase() : '';

      checkVinInput(value);
      if (usecase === 'holman') {
        setWarningVinInput(false);
      }
    }
    if (name === 'plate_number') {
      value = value ? value.toUpperCase() : '';
      setWarningVinInput(false);
    }
    if (name === 'year') {
      value = value ? parseInt(value) : null;
    }
    data[`${name}`] = value;
    setdataVehicle({ ...dataVehicle, ...data });
  };
  const changeStatus = (status) => {
    if (!sessionID) {
      return false;
    }
    const data = {};
    data['sessionID'] = sessionID;
    data['status'] = status;
    props.changeStatusInspection(data);
  };
  const updateVehicle = () => {
    let data = {};
    const checkDataInput = [];
    if (!inputVrm) {
      checkDataInput.push('vin');
    }
    if (inputVrm) {
      data['inputVrm'] = true;
    }
    if (createVehicle) {
      checkDataInput.push('body_type');
      data['createVehicle'] = createVehicle;
    }

    let checkinValid = checkDataInput.filter((item) => !dataVehicle[item]);
    let checkValid = checkDataInput.filter((item) => dataVehicle[item]);
    if (usecase !== 'holman' && checkValid && checkValid.length > 0) {
      for (let i = 0; i < checkValid.length; i++) {
        document.getElementById(checkValid[i]).classList.remove('is-invalid');
      }
    }
    if (usecase !== 'holman' && checkinValid && checkinValid.length > 0) {
      for (let i = 0; i < checkinValid.length; i++) {
        document.getElementById(checkinValid[i]).classList.add('is-invalid');
      }
      return;
    }
    data = { ...data, ...dataVehicle };
    if (createVehicle) {
      data['make'] = data['make'] ? data['make'].toUpperCase() : 'Not Decoded';
      data['model'] = data['model']
        ? data['model'].toUpperCase()
        : 'Not Decoded';
    }
    data['sessionID'] = sessionID;
    let photoCodeQuery = '01';
    if (decode === 'vms') photoCodeQuery = '08';
    const photoCode = inspTasks.find(
      (item) => item.photoCode === photoCodeQuery && item.status !== 'REJECTED',
    );
    if (photoCode) {
      const photo_id = photoCode?.photo_id ?? null;
      if (photo_id) data['photo_id'] = photo_id;
    }
    setinputVehicle(false);
    props.updateVehicleApi(data);
  };
  let sameInspectionFinished = {};
  let sameInspectionQCPassed = {};
  if (item.sameInspection) {
    const newTimeFinished = new Date(item.sameInspection.finishedAt);
    sameInspectionFinished = newTimeFinished.toLocaleString('en-US', {
      timeZone: timezone,
    });
    const newTimeQcPassed = new Date(item.sameInspection.qcPassedAt);
    sameInspectionQCPassed = newTimeQcPassed.toLocaleString('en-US', {
      timeZone: timezone,
    });
  }

  const timeVehicle = new Date(
    item && item.vehicle && item.vehicle.createdAt
      ? item.vehicle.createdAt
      : '',
  ).toLocaleString('en-US', {
    timeZone: timezone,
  });
  return (
    <div id="Inspection_view_info" className="card">
      <div className="card-body row">
        <div className="col-4 d-flex flex-column px-1">
          {displayStatusTitle(
            item.status,
            item.standard && item.standard.standard
              ? item.standard.standard
              : false,
            roleUser,
            item.sessionID,
            showModalConfirm,
          )}
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex justify-content-center">
                <CarStatus
                  qcProgress={item.qcProgress}
                  inspectProgress={item.inspectProgress}
                  tasks={tasks}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="count-down">
                {showClockQcPassed && qcPassedAt ? (
                  <CountDown end={qcPassedAt} priority_ttc={priority_ttc} />
                ) : null}
                {showClockFull && autoFinishedAt ? (
                  <CountDown end={autoFinishedAt} />
                ) : null}
                {showClockAgent && autoInspectedAt ? (
                  <CountDown end={autoInspectedAt} />
                ) : null}
                {showClockQa && autoCheckedAt ? (
                  <CountDown end={autoCheckedAt} />
                ) : null}
                {showClockValidator && autoValidatedAt ? (
                  <CountDown end={autoValidatedAt} />
                ) : null}
              </div>
              <div className="dura">
                {validatedAt && validateSuccessedAt
                  ? displayDurationTime(
                      validatedAt,
                      validateSuccessedAt,
                      'VALIDATED',
                    )
                  : null}
                {inspectedAt && inspectSuccessedAt
                  ? displayDurationTime(
                      inspectedAt,
                      inspectSuccessedAt,
                      'INSPECTED',
                    )
                  : null}
                {checkedAt && checkSuccessedAt
                  ? displayDurationTime(checkedAt, checkSuccessedAt, 'CHECKED')
                  : null}
                {managedAt && manageSuccessedAt
                  ? displayDurationTime(managedAt, manageSuccessedAt, 'MANAGED')
                  : null}
              </div>
              {usecase ? (
                <div className="mb-1 d-flex align-items-center mt-1">
                  <span
                    className="text-uppercase text-head mr-1"
                    style={{ fontSize: '14px' }}
                  >
                    <b>Use Case</b>
                  </span>
                  <div className={`badge text-uppercase badge-dark py-2`}>
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                      }}
                    >
                      {usecase}
                    </span>
                  </div>
                </div>
              ) : null}
              {checkBmw && (
                <div className="badge-group badge-group-md text-uppercase my-1 d-flex align-items-center">
                  <div
                    className={`badge badge-dark`}
                    style={{ fontSize: '14px' }}
                  >
                    <span className="text-head">User Confirm Tire</span>
                  </div>
                  {displayInfoItem(userConfirmTire ? 'Confirmed' : 'Not Yet')}
                </div>
              )}
              {showButtonChangeStatus && (
                <div className="badge-group badge-group-lg text-uppercase">
                  <div className="badge badge-primary">
                    <span className="text-head" style={{ fontSize: '16px' }}>
                      PROCESSING
                    </span>
                  </div>
                  <button
                    className="btn btn-md btn-light"
                    onClick={() => setmodalStatusInspect(true)}
                  >
                    <i
                      className="fas fa-pen"
                      data-toggle="tooltip"
                      data-placement="right"
                    />
                  </button>
                </div>
              )}
              {['ADMIN', 'TRAINER', 'SUPER'].includes(roleUser) &&
                ['FINISHED'].includes(item.status) && (
                  <div className="badge-group badge-group-lg text-uppercase">
                    <div className="badge badge-dark">
                      <span className="text-head" style={{ fontSize: '16px' }}>
                        Completed
                      </span>
                    </div>
                    <button
                      className="btn btn-md btn-light"
                      onClick={() => setmodalStatusCompleted(true)}
                    >
                      <i
                        className="fas fa-pen"
                        data-toggle="tooltip"
                        data-placement="right"
                      />
                    </button>
                  </div>
                )}
              <button
                className="btn btn-primary btn-sm mt-2"
                onClick={() => setlistUrl(true)}
                style={{
                  width: '100%',
                  height: '2.1rem',
                  fontSize: '14px',
                }}
              >
                <span className="text-bold">Url</span>
              </button>
            </div>
          </div>
          <div className="row mt-1">
            <div className="col-md-6">
              {checkShowButton && roleUser !== 'VIEWER'
                ? displayProcessReport(completedInspect, true)
                : ''}
              {checkShowButtonHolding ? (
                <button
                  className={`btn btn-warning mt-1 d-block`}
                  onClick={() => setmodalHolding(!modalHolding)}
                  style={{
                    width: '100%',
                    borderRadius: '15px',
                  }}
                >
                  <i className="fas fa-hand-holding"></i>
                  <span>Holding</span>
                </button>
              ) : null}
              {showSkipVin ? (
                <button
                  className={`btn btn-danger mt-1 d-block`}
                  onClick={() => setmodalSkipVin(true)}
                  style={{
                    width: '100%',
                    borderRadius: '15px',
                  }}
                >
                  <i className="fas fa-forward"></i>
                  <span>Skip Vin</span>
                </button>
              ) : null}
              {checkButtonInvalid ? (
                <button
                  className={`btn btn-danger mt-1 d-block`}
                  style={{
                    width: '100%',
                    borderRadius: '15px',
                  }}
                  onClick={() => setmodalInvalid(!modalInvalid)}
                >
                  <i className="fas fa-exclamation-circle"></i>
                  <span>Invalid</span>
                </button>
              ) : null}
              {checkShowButtonQcPassed ? (
                <button
                  className={`btn btn-warning mt-1 d-block`}
                  onClick={() => setmodalQcPassed(!modalQcPassed)}
                  style={{
                    width: '100%',
                    borderRadius: '15px',
                  }}
                >
                  <i className="fas fa-unlock"></i>
                  <span>QC Passed</span>
                </button>
              ) : null}
            </div>
            <div className="col-md-6 pl-1">
              <div
                className="badge badge-dark py-1 text-head mb-1"
                style={{
                  fontSize: '15px',
                }}
              >
                Repair Estimate
              </div>
              <div className="estimate">
                <div className="row">
                  <div className="col-md-6">
                    <div className="">
                      <div className="d-flex align-items-center">
                        <i className="fas fa-calculator mr-1"></i>
                        <span className="text-head">{total_exterior || 0}</span>
                      </div>
                      <div className="badge badge-dark py-1 text-head d-block">
                        Exterior
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="">
                      <div className="d-flex align-items-center">
                        <i className="fas fa-wallet mr-1"></i>
                        <span className="text-head">{currency || 'USD'}</span>
                      </div>
                      <div className="badge badge-dark py-1 text-head d-block">
                        Currency
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-md-6">
                    <div className="">
                      <div className="d-flex align-items-center">
                        <i className="fas fa-calculator mr-1"></i>
                        <span className="text-head">
                          {total_excess_wear || 0}
                        </span>
                      </div>
                      <div className="badge badge-dark py-1 text-head d-block">
                        Excess Wear
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="">
                      <div className="d-flex align-items-center">
                        <i className="fas fa-calculator mr-1"></i>
                        <span className="text-head">
                          {total_wheels_and_tires || 0}
                        </span>
                      </div>
                      <div className="badge badge-dark py-1 text-head d-block">
                        Wheels And Tires
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-8">
          <div className="row">
            {/* Vehicle data */}
            <div className="col-3 px-1">
              <div
                className={`card card_view_info badge-group badge-group-lg text-uppercase`}
              >
                <div className={`card-body d-flex flex-column`}>
                  <div className="d-flex align-items-start">
                    <div className="badge badge-dark badge_view_info_title mb-2">
                      <span className="text-head py-2">Vehicle</span>
                    </div>
                    {checkShowButtonVehicle &&
                      ![
                        'INSPECTED',
                        'CONFIRMED',
                        'FINISHED',
                        'COMLETED',
                      ].includes(item.status) &&
                      roleUser !== 'VIEWER' && (
                        <button
                          className="btn btn-md btn-warning ml-2"
                          style={{
                            paddingBottom: '2px',
                          }}
                          onClick={() => setinputVehicle(true)}
                        >
                          <i
                            className="fas fa-pen"
                            data-toggle="tooltip"
                            data-placement="right"
                          />
                        </button>
                      )}
                  </div>
                  <div className="card_icon">
                    <i className="fa fa-2x fa-tasks p-3" />
                  </div>
                  {vehicle.vrm ? displayInfoItem('PLATE', vehicle.vrm) : null}
                  {displayInfoItem('VIN', vehicle.vin ? vehicle.vin : '--')}
                  {displayInfoItem(
                    'YMM',
                    `${vehicle.year ? vehicle.year : ''} ${
                      vehicle.make ? vehicle.make : ''
                    } ${vehicle.model ? vehicle.model : ''}`,
                  )}
                  {displayInfoItem(
                    'Body Type',
                    vehicle.body_type ? vehicle.body_type : 'undefined',
                  )}
                  {displayInfoItem('Trim', vehicle.trim ? vehicle.trim : '--')}
                  {displayInfoItem(
                    'Transmission',
                    vehicle.transmission ? vehicle.transmission : '--',
                  )}
                  {displayInfoItem('Created ', timeVehicle)}
                </div>
              </div>
            </div>
            {/* Cage */}
            <div className="col-3 px-1">
              <div
                className={`card card_view_info badge-group badge-group-lg text-uppercase`}
                key="Current_cage"
              >
                <div className="card-body d-flex flex-column">
                  <div className="d-flex align-items-start">
                    <div className="badge badge-dark badge_view_info_title mb-2">
                      <span className="text-head">Cage</span>
                    </div>
                    {checkShowButtonCage &&
                      ![
                        'INSPECTED',
                        'CONFIRMED',
                        'FINISHED',
                        'COMLETED',
                      ].includes(item.status) &&
                      roleUser !== 'VIEWER' && <InspectionCage />}
                  </div>
                  <div className="card_icon">
                    <i className="fa fa-2x fa-car p-3" />
                  </div>
                  {displayInfoItem(
                    'YMM',
                    <span
                      onClick={() => newTab(cage._id, 'cage')}
                      className="text-head text-primary"
                      style={{ cursor: 'pointer' }}
                    >
                      {`${cage.year ? cage.year : ''} ${
                        cage.make ? cage.make : ''
                      } ${cage.model ? cage.model : ''}`}
                    </span>,
                  )}
                  {displayInfoItem('Body Type', cage.bodyType || '--')}
                  {displayInfoItem('Trim', cage.trim || '--')}
                  {displayInfoItem('Class', cage.class || '--')}
                </div>
              </div>
            </div>
            {/* Time */}
            <div className="col-3 px-1">
              <div
                className={`card card_view_info badge-group badge-group-lg text-uppercase`}
              >
                <div className="card-body d-flex flex-column">
                  <div className="badge badge-dark badge_view_info_title mb-2">
                    <span className="text-head">Time</span>
                  </div>
                  <div className="card_icon">
                    <i className="fa fa-2x fa-clock p-3" />
                  </div>
                  {displayInfoItem('Created ', createdAt7)}
                  {displayInfoItem('QcPassed ', qcPassedAt7 || '--')}
                  {displayInfoItem('Process ', processAt7 || '--')}
                  {displayInfoItem('Finished ', finishedAt7 || '--')}
                </div>
              </div>
            </div>
            {/* Data same vin */}
            {item.sameInspection && (
              <div className="col-3 px-1">
                <div
                  className={`card card_view_info badge-group badge-group-lg text-uppercase`}
                >
                  <div className="card-body d-flex flex-column">
                    <div className="badge badge-dark badge_view_info_title mb-2">
                      <span className="text-head">Data Same Vin</span>
                    </div>
                    <div className="card_icon">
                      <i className="fa fa-2x fa-database p-3" />
                    </div>
                    {displayInfoItem(
                      'Session',
                      dataSameVinInspect ? (
                        <span
                          onClick={() =>
                            newTab(dataSameVinInspect._id, 'inspection')
                          }
                          className="text-head text-primary"
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          {dataSameVinInspect.sessionID}
                        </span>
                      ) : (
                        '--'
                      ),
                    )}
                    {displayInfoItem(
                      'Finished',
                      dataSameVinInspect && dataSameVinInspect.finishedAt
                        ? sameInspectionFinished
                        : '--',
                    )}
                    {displayInfoItem(
                      'Qc Passed',
                      dataSameVinInspect && dataSameVinInspect.qcPassedAt
                        ? sameInspectionQCPassed
                        : '--',
                    )}
                    {displayInfoItem(
                      'Exterior',
                      dataSameVinInspect?.repair_estimate?.total_exterior,
                    )}
                    {displayInfoItem(
                      'Currency',
                      dataSameVinInspect?.repair_estimate?.currency,
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showInfoScroll && (
        <div
          className="info-scroll d-flex align-items-center"
          style={{
            position: 'fixed',
            width: '95%',
            height: '8%',
            left: '2.5%',
            top: '80px',
            padding: '5px 15px',
            borderRadius: '10px',
            background: '#f8f9fa',
            justifyContent: 'center',
            zIndex: '10',
            border: '1px solid #321640',
          }}
        >
          <div
            className="id mr-1"
            style={{
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: '18px',
            }}
          >
            {inspectionID} / {sessionID}
          </div>
          {priority_level ? (
            <div
              style={{ fontSize: '18px' }}
              className={`badge text-head mr-1 ${
                ['F1'].includes(priority_level) ? 'badge-warning' : 'badge-dark'
              } ml-2`}
            >
              {priority_level}
            </div>
          ) : null}

          <div className="badge-group badge-group-lg text-uppercase mr-1">
            <div className="d-flex justify-content-between align-items-center flex-wrap text-head">
              {showButtonChangeStatus && (
                <div className="badge-group badge-group-lg text-uppercase">
                  <button
                    className="btn btn-tiny btn-light mr-1"
                    onClick={() => setmodalStatusInspect(true)}
                  >
                    <i
                      className="fas fa-pen"
                      data-toggle="tooltip"
                      data-placement="right"
                    />
                  </button>
                </div>
              )}
              <div>{displayStatusLabelScroll(item.status)}</div>
              {checkShowButtonHolding ? (
                <button
                  className={`btn btn-warning mr-1`}
                  onClick={() => setmodalHolding(!modalHolding)}
                  style={{
                    borderRadius: '15px',
                  }}
                >
                  <i className="fas fa-hand-holding"></i>
                  <span>Holding</span>
                </button>
              ) : null}
              <div className="mr-2">
                {checkShowButton && roleUser !== 'VIEWER'
                  ? displayProcessReport(completedInspect, true)
                  : ''}
              </div>
            </div>
          </div>

          {showClockQcPassed && qcPassedAt ? (
            <div
              style={{
                width: '10%',
              }}
            >
              <CountDown end={qcPassedAt} priority_ttc={priority_ttc} />
            </div>
          ) : null}

          {showClockFull && autoFinishedAt ? (
            <CountDown end={autoFinishedAt} />
          ) : null}
          {showClockAgent && autoInspectedAt ? (
            <CountDown end={autoInspectedAt} />
          ) : null}
          {showClockQa && autoCheckedAt ? (
            <CountDown end={autoCheckedAt} />
          ) : null}
          {showClockValidator && autoValidatedAt ? (
            <CountDown end={autoValidatedAt} />
          ) : null}
          {ppWarning}
          {showSttPhoto}
        </div>
      )}
      <ModalMD
        show={inputVehicle}
        title={
          <div
            className="custom-control custom-switch"
            style={{ display: 'flex' }}
          >
            <input
              type="checkbox"
              className="custom-control-input"
              id="inputVehicle"
              name="inputVehicle"
              checked={createVehicle}
              onChange={onToggleCreate}
            />
            {!createVehicle ? (
              <label className="custom-control-label" htmlFor="inputVehicle">
                Decode
              </label>
            ) : (
              <label className="custom-control-label" htmlFor="inputVehicle">
                Create Vehicle
              </label>
            )}
          </div>
        }
        body={
          createVehicle ? (
            <div>
              <div className="data-inspect">
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input "
                    id="inputVrm"
                    name="inputVrm"
                    checked={inputVrm}
                    onChange={() => setinputVrm(!inputVrm)}
                  />
                  {!inputVrm ? (
                    <label
                      className="custom-control-label text-head mb-1"
                      htmlFor="inputVrm"
                    >
                      <div
                        style={{
                          display: 'inline-block',
                        }}
                        className="badge badge-dark text-head text-uppercase mr-2"
                      >
                        Vin
                      </div>
                      {vehicle && vehicle.vin
                        ? vehicle.vin.toUpperCase()
                        : null}
                    </label>
                  ) : (
                    <label
                      className="custom-control-label text-head mb-1"
                      htmlFor="inputVrm"
                    >
                      <div
                        style={{
                          display: 'inline-block',
                        }}
                        className="badge badge-dark text-head text-uppercase mr-2"
                      >
                        Plate
                      </div>
                      {vehicle?.plate_number?.toUpperCase() ?? null}
                    </label>
                  )}
                </div>
                {!inputVrm ? (
                  <div>
                    <input
                      style={{
                        textTransform: 'uppercase',
                      }}
                      type="text"
                      className="form-control"
                      id="vin"
                      name="vin"
                      onChange={(e) => handleOptionChange(e)}
                      value={dataVehicle['vin'] ?? ''}
                    />
                    {usecase === 'holman' ? (
                      <div>
                        <div className="d-flex">
                          <div
                            style={{ fontSize: '16px' }}
                            className="badge badge-dark d-inline text-head text-uppercase mt-1"
                          >
                            Serial
                          </div>
                        </div>
                        <input
                          style={{
                            textTransform: 'uppercase',
                            transition: 'all .3s ease',
                          }}
                          type="text"
                          className="form-control mt-1"
                          id="serial"
                          name="serial"
                          value={
                            dataVehicle['serial'] ? dataVehicle['serial'] : ''
                          }
                          onChange={(e) => handleOptionChange(e)}
                        />
                        <div className="d-flex">
                          <div
                            style={{ fontSize: '16px' }}
                            className="badge badge-dark d-inline text-head text-uppercase mt-1"
                          >
                            Pin
                          </div>
                        </div>
                        <input
                          style={{
                            textTransform: 'uppercase',
                            transition: 'all .3s ease',
                          }}
                          type="text"
                          className="form-control mt-1"
                          id="pin"
                          name="pin"
                          value={dataVehicle['pin'] ? dataVehicle['pin'] : ''}
                          onChange={(e) => handleOptionChange(e)}
                        />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <input
                    style={{
                      textTransform: 'uppercase',
                    }}
                    type="text"
                    className="form-control"
                    id="plate_number"
                    name="plate_number"
                    onChange={(e) => handleOptionChange(e)}
                    value={dataVehicle['plate_number'] ?? ''}
                  />
                )}
              </div>
              <div className="group" style={{ display: 'flex' }}>
                <div className="data-inspect">
                  <label className="text-head mb-1">
                    <div
                      style={{
                        display: 'inline-block',
                      }}
                      className="badge badge-dark text-head text-uppercase mr-2"
                    >
                      Make
                    </div>
                    {vehicle && vehicle.make ? vehicle.make : null}
                  </label>
                  <input
                    style={{
                      transition: 'all .3s ease',
                    }}
                    id="make"
                    type="text"
                    className="form-control"
                    name="make"
                    onChange={(e) => handleOptionChange(e)}
                    value={dataVehicle['make']}
                  />
                </div>
                <div className="data-inspect">
                  <label className="text-head mb-1">
                    <div
                      style={{
                        display: 'inline-block',
                      }}
                      className="badge badge-dark text-head text-uppercase mr-2"
                    >
                      Year
                    </div>
                    {vehicle && vehicle.year ? vehicle.year : null}
                  </label>
                  <input
                    style={{
                      textTransform: 'uppercase',
                      transition: 'all .3s ease',
                    }}
                    type="number"
                    className="form-control"
                    id="year"
                    name="year"
                    onChange={(e) => handleOptionChange(e)}
                    value={dataVehicle['year']}
                  />
                </div>
              </div>
              <div className="data-inspect">
                <label className="text-head mb-1">
                  <div
                    style={{
                      display: 'inline-block',
                    }}
                    className="badge badge-dark text-head text-uppercase mr-2"
                  >
                    Model
                  </div>
                  {vehicle && vehicle.model ? vehicle.model : null}
                </label>
                <input
                  style={{
                    transition: 'all .3s ease',
                  }}
                  type="text"
                  className="form-control"
                  id="model"
                  name="model"
                  onChange={(e) => handleOptionChange(e)}
                  value={dataVehicle['model']}
                />
              </div>
              <div className="data-inspect mb-2">
                <label className="text-head mb-1">
                  <div
                    style={{
                      display: 'inline-block',
                    }}
                    className="badge badge-dark text-head text-uppercase mr-2"
                  >
                    Body Type
                  </div>
                  {vehicle && vehicle.body_type ? vehicle.body_type : null}
                </label>
                <input
                  style={{
                    transition: 'all .3s ease',
                  }}
                  type="text"
                  id="body_type"
                  className="form-control"
                  name="body_type"
                  onChange={(e) => handleOptionChange(e)}
                  value={dataVehicle['body_type'] || dataVehicle['bodyType']}
                />
              </div>
              <div className="group" style={{ display: 'flex' }}>
                <button
                  className="btn btn-success"
                  onClick={() => updateVehicle()}
                  style={{ width: '49%' }}
                  disabled={warningVinInput}
                >
                  <i className="fas fa-check"></i>
                </button>
                <button
                  style={{ width: '49%' }}
                  className="btn btn-danger"
                  onClick={() => setinputVehicle(false)}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className="form-group mb-0">
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input "
                    id="inputVrm"
                    name="inputVrm"
                    checked={inputVrm}
                    onChange={() => setinputVrm(!inputVrm)}
                  />
                  {!inputVrm ? (
                    <label
                      className="custom-control-label text-head mb-1"
                      htmlFor="inputVrm"
                    >
                      <div
                        style={{
                          display: 'inline-block',
                        }}
                        className="badge badge-dark text-head text-uppercase mr-2"
                      >
                        Vin
                      </div>
                      {vehicle && vehicle.vin
                        ? vehicle.vin.toUpperCase()
                        : null}
                    </label>
                  ) : (
                    <label
                      className="custom-control-label text-head mb-1"
                      htmlFor="inputVrm"
                    >
                      <div
                        style={{
                          display: 'inline-block',
                        }}
                        className="badge badge-dark text-head text-uppercase mr-2"
                      >
                        Plate
                      </div>
                      {vehicle && vehicle.plate_number
                        ? vehicle.plate_number.toUpperCase()
                        : null}
                    </label>
                  )}
                </div>
                <div className="d-flex">
                  {!inputVrm ? (
                    <input
                      style={{
                        textTransform: 'uppercase',
                        transition: 'all .3s ease',
                      }}
                      placeholder="VIN"
                      type="text"
                      className="form-control"
                      id="vin"
                      name="vin"
                      maxLength={17}
                      value={dataVehicle['vin'] ?? ''}
                      onChange={(e) => handleOptionChange(e)}
                    />
                  ) : (
                    <input
                      style={{
                        textTransform: 'uppercase',
                      }}
                      type="text"
                      className="form-control"
                      placeholder="PLATE"
                      id="plate_number"
                      name="plate_number"
                      onChange={(e) => handleOptionChange(e)}
                      value={dataVehicle['plate_number'] ?? ''}
                    />
                  )}

                  <button
                    className="btn btn-success"
                    onClick={() => updateVehicle()}
                    disabled={warningVinInput}
                  >
                    <i className="fas fa-check"></i>
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => setinputVehicle(false)}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
              {warningVinInput ? (
                <span className="text-danger ml-2">
                  VIN must be 17 characters and not include I, O, Q letter
                </span>
              ) : null}
            </div>
          )
        }
        sizeModal="modal-md"
        handleClose={() => {
          setinputVehicle(false);
          setWarningVinInput(false);
          setdataVehicle({});
        }}
      />
      <ModalMD
        show={listUrl}
        title="List Url"
        body={
          <div className="main-content">
            <div
              className="form-group"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {listUrlOriginal &&
                listUrlOriginal.length &&
                listUrlOriginal.map((item, key) => {
                  return (
                    <span key={`item_key_${key}`} className="link">
                      {item}
                    </span>
                  );
                })}
            </div>
          </div>
        }
        size="lg"
        handleClose={() => setlistUrl(false)}
      />

      <ModalMD
        show={modalStatusInspect}
        title="Change status Inspection"
        body={
          <div className="main-content">
            <div className="form-group">
              <div className="status-inspection text-center">
                <div className={`badge badge-success`}>
                  <span className="text-head" style={{ fontSize: '20px' }}>
                    FINISHED
                  </span>
                </div>
                <i
                  className="fas fa-long-arrow-alt-right mr-2 ml-2"
                  style={{ fontSize: '25px' }}
                ></i>
                <div className={`badge badge-primary`}>
                  <span className="text-head" style={{ fontSize: '20px' }}>
                    PROCESSING
                  </span>
                </div>
              </div>
            </div>

            <button
              className="btn btn-warning float-right"
              onClick={() => changeStatus('PROCESSING')}
            >
              <span className="text-bold" style={{ fontSize: '14px' }}>
                Change
              </span>
            </button>
          </div>
        }
        sizeModal="modal-md"
        handleClose={() => setmodalStatusInspect(false)}
      />
      <ModalMD
        show={modalStatusCompleted}
        title="Change status Inspection"
        body={
          <div className="main-content">
            <div className="form-group">
              <div className="status-inspection text-center">
                <div className={`badge badge-success`}>
                  <span className="text-head" style={{ fontSize: '20px' }}>
                    FINISHED
                  </span>
                </div>
                <i
                  className="fas fa-long-arrow-alt-right mr-2 ml-2"
                  style={{ fontSize: '25px' }}
                ></i>
                <div className={`badge badge-dark`}>
                  <span className="text-head" style={{ fontSize: '20px' }}>
                    COMPLETED
                  </span>
                </div>
              </div>
            </div>

            <button
              className="btn btn-warning float-right"
              onClick={() => changeStatus('COMPLETED')}
            >
              <span className="text-bold" style={{ fontSize: '14px' }}>
                Change
              </span>
            </button>
          </div>
        }
        sizeModal="modal-md"
        handleClose={() => setmodalStatusCompleted(false)}
      />
      <ModalMD
        show={modalCompleted}
        title="Completed"
        body={
          <div className="main-content">
            <div className="form-group">
              <div className="text-center">
                <span className="text-head" style={{ fontSize: '20px' }}>
                  {!checkCompleted
                    ? 'Are you sure you want to finish it?'
                    : 'Missing Photo ,Are you sure you want to finish it'}
                </span>
              </div>
            </div>
            <div className="btn-group float-right">
              <button className="btn btn-warning" onClick={completeInspection}>
                <span className="text-bold" style={{ fontSize: '14px' }}>
                  Yes
                </span>
              </button>
              <button
                className="btn btn-primary"
                onClick={() => setmodalCompleted(false)}
              >
                <span className="text-bold" style={{ fontSize: '14px' }}>
                  No
                </span>
              </button>
            </div>
          </div>
        }
        sizeModal="modal-md"
        handleClose={() => setmodalCompleted(false)}
      />
      <ModalMD
        show={modalInvalid}
        title="INVALID"
        body={
          <div className="main-content">
            <div className="form-group">
              <div className="text-center">
                <span className="text-head" style={{ fontSize: '20px' }}>
                  INVALID !!!!!!!!!
                </span>
              </div>
            </div>
            <div className="btn-group float-right">
              <button className="btn btn-warning" onClick={invalidInspection}>
                <span className="text-bold" style={{ fontSize: '14px' }}>
                  Yes
                </span>
              </button>
              <button
                className="btn btn-primary"
                onClick={() => setmodalInvalid(false)}
              >
                <span className="text-bold" style={{ fontSize: '14px' }}>
                  No
                </span>
              </button>
            </div>
          </div>
        }
        sizeModal="modal-md"
        handleClose={() => setmodalInvalid(false)}
      />
      <ModalMD
        show={modalQcPassed}
        title="Qc Passed"
        body={
          <div className="main-content">
            <div className="form-group">
              <div className="text-center">
                <span className="text-head" style={{ fontSize: '20px' }}>
                  QC PASSED !!!!!!
                </span>
              </div>
            </div>
            <div className="btn-group float-right">
              <button className="btn btn-warning" onClick={qcPassedInspection}>
                <span className="text-bold" style={{ fontSize: '14px' }}>
                  Yes
                </span>
              </button>
              <button
                className="btn btn-primary"
                onClick={() => setmodalQcPassed(false)}
              >
                <span className="text-bold" style={{ fontSize: '14px' }}>
                  No
                </span>
              </button>
            </div>
          </div>
        }
        sizeModal="modal-md"
        handleClose={() => setmodalQcPassed(false)}
      />
      <ModalMD
        show={showModalTimeOut}
        title="Time Out"
        body={
          <div className="main-content">
            <div className="form-group">
              <div className="text-center">
                <SVG src={'/img/svg/oops.svg'} uniquifyIDs={false}></SVG>
                <span className="text-head" style={{ fontSize: '20px' }}>
                  Hey Try your best next time !!!
                </span>
              </div>
            </div>
            <div className="btn-group float-right">
              <button className="btn btn-warning" onClick={logOut}>
                <span className="text-bold" style={{ fontSize: '14px' }}>
                  Yes
                </span>
              </button>
            </div>
          </div>
        }
        sizeModal="modal-md"
      />
      <ModalMD
        show={modalWarningVehicle}
        title="Vehicle Error"
        body={
          <div className="main-content">
            <div className="form-group">
              <div className="text-center">
                <SVG src={'/img/svg/oops.svg'} uniquifyIDs={false}></SVG>
                <span className="text-head" style={{ fontSize: '20px' }}>
                  Vehicle Error Plz Check
                </span>
              </div>
            </div>
            <div className="btn-group float-right">
              <button
                className="btn btn-warning"
                onClick={() => setmodalWarningVehicle(false)}
              >
                <span className="text-bold" style={{ fontSize: '14px' }}>
                  Ok
                </span>
              </button>
            </div>
          </div>
        }
        handleClose={() => setmodalWarningVehicle(false)}
        sizeModal="modal-md"
      />
      <ModalMD
        show={modalSkipVin}
        title="Skip Vin"
        body={
          <div className="main-content">
            <div className="form-group">
              <div className="text-center">
                <span className="text-head" style={{ fontSize: '20px' }}>
                  This Vin Will be not use in Same Vin anymore , are you sure ?
                </span>
              </div>
            </div>
            <div className="btn-group float-right">
              <button className="btn btn-warning" onClick={putSkipVin}>
                <span className="text-bold" style={{ fontSize: '14px' }}>
                  Yes
                </span>
              </button>
              <button
                className="btn btn-primary"
                onClick={() => setmodalSkipVin(false)}
              >
                <span className="text-bold" style={{ fontSize: '14px' }}>
                  No
                </span>
              </button>
            </div>
          </div>
        }
        sizeModal="modal-md"
        handleClose={() => setmodalSkipVin(false)}
      />
      <ModalMD
        show={modalHolding}
        title="Holding"
        body={
          <div className="main-content">
            <div className="form-group">
              <div className="text-center">
                <span className="text-head" style={{ fontSize: '20px' }}>
                  HOLDINGGGG !!!!!!
                </span>
              </div>
            </div>
            <div className="btn-group float-right">
              <button className="btn btn-warning" onClick={holdingInspection}>
                <span className="text-bold" style={{ fontSize: '14px' }}>
                  Yes
                </span>
              </button>
              <button
                className="btn btn-primary"
                onClick={() => setmodalHolding(false)}
              >
                <span className="text-bold" style={{ fontSize: '14px' }}>
                  No
                </span>
              </button>
            </div>
          </div>
        }
        sizeModal="modal-md"
        handleClose={() => setmodalHolding(false)}
      />
      <ModalMD
        show={modalConfirm}
        title="Set Standard"
        body={
          <div className="main-content">
            <div className="form-group">
              <div className="text-center">
                <span className="text-head" style={{ fontSize: '20px' }}>
                  {`Are u sure , that u want to ${
                    dataUpdate.standard ? 'use' : 'remove'
                  } this ${dataUpdate.sessionID} ?`}
                </span>
              </div>
            </div>
            <div className="btn-group float-right">
              <button className="btn btn-warning" onClick={adminSetStandard}>
                <span className="text-bold" style={{ fontSize: '14px' }}>
                  Yes
                </span>
              </button>
              <button
                className="btn btn-primary"
                onClick={() => setmodalConfirm(false)}
              >
                <span className="text-bold" style={{ fontSize: '14px' }}>
                  No
                </span>
              </button>
            </div>
          </div>
        }
        sizeModal="modal-md"
        handleClose={() => setmodalConfirm(false)}
      />
    </div>
  );
};

InspectionViewInfo.propTypes = {
  item: PropTypes.object.isRequired,
};

InspectionViewInfo.defaultProps = {
  show: true,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {
  changeStatusInspection,
  updateVehicleApi,
  adminSetStandard,
  clearInspection,
  holding,
  putSkipVin,
  invalid,
  qcPassed,
  logout,
})(InspectionViewInfo);
